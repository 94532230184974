// Login
export const LOGIN = 'Login';
export const RESET_PASSWORD = 'ResetPassword';

// Main
export const MAIN = 'Main';

// Profile
export const PROFILE = 'Profile';

// Chat
export const CHAT = 'Chat';

// Cards
export const CARDS = 'Cards';

// Assets
export const ASSETS = 'Assets';
export const ASSETS_PARENT = 'AssetsParent';
export const ASSETS_ARCHIVE = 'AssetsArchive';
export const ASSETS_LAYOUT = 'AssetsLayout';

// Projects
export const PROJECTS_CREATE = 'ProjectsCreate';
export const PROJECTS_EDIT = 'ProjectsEdit';
export const PROJECTS_LAYOUT = 'ProjectsLayout';
export const PROJECTS_DETAILED = 'ProjectsDetailed';
export const PROJECTS_ARCHIVE = 'ProjectsArchive';
export const PROJECTS = 'Projects';
export const PROJECTS_PARENT = 'ProjectsParent';

// Clients
export const CLIENTS = 'Clients';
export const CLIENTS_PARENT = 'ClientsParent';
export const CLIENTS_CREATE = 'ClientsCreate';
export const CLIENTS_EDIT = 'ClientsEdit';
export const CLIENTS_ARCHIVE = 'ClientsArchive';
export const CLIENTS_UNCONFIRMED = 'ClientsUnconfirmed';
export const CLIENTS_TENANTS = 'ClientsTenants';
export const CLIENTS_SHORT_TENANTS = 'ClientsShortTermTenants';
export const CLIENTS_DETAILED = 'ClientsDetailed';
export const CLIENTS_USAGE_DETAILED = 'ClientsUsageDetail';

// Contractors
export const CONTRACTORS = 'Contractors';
export const CONTRACTORS_LAYOUT = 'ContractorsLayout';
export const CONTRACTORS_DETAILED = 'ContractorsDetailed';
export const CONTRACTORS_PARENT = 'ContractorsParent';

// Buildings
export const BUILDINGS_CREATE = 'BuildingsCreate';
export const BUILDINGS_EDIT = 'BuildingsEdit';
export const BUILDINGS_DETAILED = 'BuildingsDetailed';

// Units
export const UNITS_CREATE = 'UnitsCreate';
export const UNITS_EDIT = 'UnitsEdit';
export const UNITS_DETAILED = 'UnitsDetailed';

// Rooms
export const ROOMS_CREATE = 'RoomsCreate';
export const ROOMS_EDIT = 'RoomsEdit';
export const ROOMS_DETAILED = 'RoomsDetailed';

// Invoices
export const INVOICES_CREATE = 'InvoicesCreate';
export const INVOICES = 'Invoices';
export const INVOICES_UNPAID = 'InvoicesUnpaid';
export const INVOICES_PAID = 'InvoicesPaid';
export const INVOICES_PAYMENT = 'InvoicesInside';
export const INVOICES_PARENT = 'InvoicesParent';
export const INVOICES_RECURRENCE_CREATE = 'InvoicesRecurrenceCreate';
export const INVOICES_RECURRENCE_EDIT = 'InvoicesRecurrenceEdit';
export const INVOICES_RECURRENCE = 'InvoicesRecurrence';

// Announcements
export const ANNOUNCEMENTS_PARENT = 'AnnouncementsParent';
export const ANNOUNCEMENTS_SENT = 'AnnouncementsSent';
export const ANNOUNCEMENTS_SCHEDULED = 'AnnouncementsScheduled';
export const ANNOUNCEMENTS_DRAFTS = 'AnnouncementsDrafts';
export const ANNOUNCEMENTS_CREATE = 'AnnouncementsCreate';
export const ANNOUNCEMENTS_EDIT = 'AnnouncementsEdit';
export const ANNOUNCEMENTS_DETAILED = 'AnnouncementsDetailed';

// Dashboard
export const DASHBOARD = 'Dashboard';
export const DASHBOARD_PARENT = 'DashboardParent';
export const DASHBOARD_PAYMENTS = 'DashboardPayments';
export const DASHBOARD_TASKS = 'DashboardTasks';

// Employees
export const EMPLOYEES = 'Employees';
export const EMPLOYEES_CREATE = 'EmployeesCreate';
export const EMPLOYEES_EDIT = 'EmployeesEdit';
export const EMPLOYEES_ARCHIVE = 'EmployeesArchive';
export const EMPLOYEES_ROLES = 'EmployeesRoles';
export const EMPLOYEES_DETAILED = 'EmployeesDetailed';
export const EMPLOYEES_LAYOUT = 'EmployeesLayout';
export const EMPLOYEES_PARENT = 'EmployeesParent';

// Roles
export const ROLES = 'Roles';
export const ROLES_CREATE = 'RolesCreate';
export const ROLES_EDIT = 'RolesEdit';
export const ROLES_DETAILED = 'RolesDetailed';

// Contracts
export const CONTRACTS_PARENT = 'ContractsParent';
export const CONTRACTS = 'Contracts';
export const CONTRACTS_ARCHIVE = 'ContractsArchive';
export const CONTRACTS_DETAILED = 'ContractsDetailed';

// Templates
export const TEMPLATES = 'Templates';
export const AGREEMENTS = 'Agreements';
export const AGREEMENTS_DETAILED = 'AgreementsDeatiled';
export const TEMPLATES_EDIT = 'TemplatesCreate';
export const ALL_RECORDS = 'AllRecords';

// Task
export const TASKS_PARENT = 'TasksParent';
export const TASKS = 'Tasks';
export const TASKS_ALL = 'TasksAll';
export const TASKS_IN_PROGRESS = 'TaskInProgress';
export const TASKS_CANCELED_AND_COMPLETED = 'CanceledAndCompleted';
export const TASKS_RECURRENCE = 'TasksRecurrence';
export const TASKS_CREATE_WITH_TEMPLATE = 'TasksCreateWithTemplate';
export const TASKS_CREATE = 'TasksCreate';
export const TASKS_RECURRENCE_CREATE = 'TasksRecurrenceCreate';
export const TASKS_RECURRENCE_DETAILED = 'TasksRecurrenceDetailed';
export const TASKS_DETAILED = 'TasksDetailed';
export const TASKS_EDIT = 'TasksEdit';
export const TASKS_RECURRENCE_EDIT = 'TasksRecurrenceEdit';

// Services
export const SERVICES_PARENT = 'ServicesParent';
export const SERVICES = 'Services';
export const SERVICES_CREATE = 'ServicesCreate';
export const SERVICES_EDIT = 'ServicesEdit';
export const SERVICES_ARCHIVE = 'ServicesArchive';
export const SERVICES_DETAILED = 'ServicesDetailed';
export const SERVICES_CATEGORY_CREATE = 'ServicesCategoryCreate';
export const SERVICES_CATEGORY_EDIT = 'ServicesCategoryEdit';

// Requests
export const REQUESTS_PARENT = 'RequestsParent';
export const REQUESTS = 'Requests';
export const REQUESTS_CREATE = 'RequestsCreate';
export const REQUESTS_EDIT = 'RequestsEdit';
export const REQUESTS_ARCHIVE = 'RequestsArchive';
export const REQUESTS_DETAILED = 'RequestsDetailed';
export const REQUESTS_TYPE_CREATE = 'RequestsTypeCreate';
export const REQUESTS_TYPE_EDIT = 'RequestsTypeEdit';
export const REQUESTS_CATEGORY_CREATE = 'RequestsCategoryCreate';
export const REQUESTS_CATEGORY_EDIT = 'RequestsCategoryEdit';

// Access Denied
export const ACCESS_DENIED = 'AccessDenied';
