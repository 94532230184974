import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import * as entityStatuses from '@/constants/entityStatuses';
import { canAbility, accessToTemplates } from './middleware';

export default {
  path: '/contracts',
  name: routes.CONTRACTS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.TEMPLATES },
  children: [
    {
      path: '/contracts/templates',
      name: routes.TEMPLATES,
      component: () => import('@/views/Contracts/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTS), accessToTemplates()],
      },
      props: { status: entityStatuses.TEMPLATES },
    },
    {
      path: '/contracts/agreements',
      name: routes.AGREEMENTS,
      component: () => import('@/views/Contracts/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTS), accessToTemplates()],
      },
      props: route => ({ status: entityStatuses.AGREEMENTS, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/contracts/agreements/:id',
      name: routes.AGREEMENTS_DETAILED,
      component: () => import('@/views/Contracts/AgreementDeatailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTS), accessToTemplates()],
      },
      props: route => ({
        id: Number(route.params.id),
        status: entityStatuses.AGREEMENTS,
        page: Number(route.query.page) || 1,
      }),
    },
    {
      path: '/contracts/:id/edit',
      name: routes.CONTRACTS_DETAILED,
      component: () => import('@/views/Contracts/Edit.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.CONTRACTS)],
      },
      props: route => ({
        id: Number(route.params.id),
        from:
          route.query.pageName && route.query.id ? { name: route.query.pageName, params: { id: route.query.id } } : {},
      }),
    },
    {
      path: '/contracts/templates/:id/edit',
      name: routes.TEMPLATES_EDIT,
      component: () => import('@/views/Templates/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTS)],
      },
      props: route => ({ id: Number(route.params.id) }),
    },
  ],
};
