import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import * as taskStatuses from '@/constants/taskStatuses';
import { canAbility, accessToRequests } from './middleware';

export default {
  path: '/tasks',
  name: routes.TASKS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.TASKS },
  children: [
    {
      path: '/tasks',
      name: routes.TASKS,
      component: () => import('@/views/Tasks/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ status: taskStatuses.PENDING, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/tasks/all',
      name: routes.TASKS_ALL,
      component: () => import('@/views/Tasks/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ status: taskStatuses.ALL, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/tasks/in-progress',
      name: routes.TASKS_IN_PROGRESS,
      component: () => import('@/views/Tasks/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ status: taskStatuses.IN_PROGRESS, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/tasks/canceled-and-completed',
      name: routes.TASKS_CANCELED_AND_COMPLETED,
      component: () => import('@/views/Tasks/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ status: taskStatuses.CANCELED_AND_COMPLETED, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/tasks/recurrence',
      name: routes.TASKS_RECURRENCE,
      component: () => import('@/views/Tasks/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ status: taskStatuses.RECURRENCE, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/tasks/recurrence/create',
      name: routes.TASKS_RECURRENCE_CREATE,
      component: () => import('@/views/Tasks/RecurrenceModify.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ isEdit: false, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/tasks/recurrence/:id/edit',
      name: routes.TASKS_RECURRENCE_EDIT,
      component: () => import('@/views/Tasks/RecurrenceModify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.ISSUES)],
      },
      props: route => ({ isEdit: true, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/tasks/recurrence/:id',
      name: routes.TASKS_RECURRENCE_DETAILED,
      component: () => import('@/views/Tasks/RecurrenceDetailed.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.ISSUES)],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query.prevPage }),
    },
    {
      path: '/tasks/create-with-template',
      name: routes.TASKS_CREATE_WITH_TEMPLATE,
      component: () => import('@/views/Tasks/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.ISSUES), accessToRequests()],
      },
      props: { useTemplate: true },
    },
    {
      path: '/tasks/create',
      name: routes.TASKS_CREATE,
      component: () => import('@/views/Tasks/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.ISSUES)],
      },
    },
    {
      path: '/tasks/:id',
      name: routes.TASKS_DETAILED,
      component: () => import('@/views/Tasks/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.ISSUES)],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query.prevPage }),
    },
    {
      path: '/tasks/:id/edit',
      name: routes.TASKS_EDIT,
      component: () => import('@/views/Tasks/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.ISSUES)],
      },
      props: { isEdit: true },
    },
  ],
};
